import {
  Box,
  Button,
  ChakraProps,
  Checkbox,
  CheckboxGroup,
  Divider,
  FormControl,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  Icon,
  IconButton,
  Input,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Radio,
  RadioGroup,
  Slider,
  SliderFilledTrack,
  SliderThumb,
  SliderTrack,
  Stack,
  Text,
  Textarea,
  Tooltip,
  VStack,
  shouldForwardProp
} from '@chakra-ui/react'
import {
  mdiArrowDown,
  mdiArrowUp,
  mdiChevronDown,
  mdiContentDuplicate,
  mdiInformationOutline,
  mdiPencil,
  mdiPlus,
  mdiTrashCanOutline
} from '@mdi/js'
import { Form } from '@rjsf/chakra-ui'
import { FormProps } from '@rjsf/core'
import {
  ArrayFieldTemplateItemType,
  ArrayFieldTemplateProps,
  BaseInputTemplateProps,
  EnumOptionsType,
  FieldHelpProps,
  FieldTemplateProps,
  FormContextType,
  IconButtonProps,
  ObjectFieldTemplateProps,
  RJSFSchema,
  StrictRJSFSchema,
  UiSchema,
  WidgetProps,
  ariaDescribedByIds,
  canExpand,
  descriptionId,
  enumOptionsIndexForValue,
  enumOptionsIsSelected,
  enumOptionsValueForIndex,
  examplesId,
  getInputProps,
  getTemplate,
  getUiOptions,
  helpId,
  labelValue,
  optionId,
  rangeSpec,
  schemaRequiresTrueValue,
  titleId
} from '@rjsf/utils'
import { OptionsOrGroups } from 'chakra-react-select'
import { ChangeEvent, FocusEvent, useMemo, useState } from 'react'
import { Select } from './Select.js'

export function ArrayFieldItemTemplate<
  T = any,
  S extends StrictRJSFSchema = RJSFSchema,
  F extends FormContextType = any
>(props: ArrayFieldTemplateItemType<T, S, F>) {
  const {
    children,
    disabled,
    hasToolbar,
    hasCopy,
    hasMoveDown,
    hasMoveUp,
    hasRemove,
    index,
    onCopyIndexClick,
    onDropIndexClick,
    onReorderClick,
    readonly,
    uiSchema,
    schema,
    registry
  } = props
  const { CopyButton, MoveDownButton, MoveUpButton, RemoveButton } = registry.templates.ButtonTemplates
  const onCopyClick = useMemo(() => onCopyIndexClick(index), [index, onCopyIndexClick])

  const onRemoveClick = useMemo(() => onDropIndexClick(index), [index, onDropIndexClick])

  const onArrowUpClick = useMemo(() => onReorderClick(index, index - 1), [index, onReorderClick])

  const onArrowDownClick = useMemo(() => onReorderClick(index, index + 1), [index, onReorderClick])

  return (
    <VStack alignItems={'flex-end'} position='relative'>
      {hasToolbar && (
        <HStack position='absolute' top={schema.type == 'object' ? '6px' : '2px'} right={0} zIndex={1}>
          {(hasMoveUp || hasMoveDown) && (
            <MoveUpButton
              disabled={disabled || readonly || !hasMoveUp}
              onClick={onArrowUpClick}
              uiSchema={uiSchema}
              registry={registry}
            />
          )}
          {(hasMoveUp || hasMoveDown) && (
            <MoveDownButton
              disabled={disabled || readonly || !hasMoveDown}
              onClick={onArrowDownClick}
              uiSchema={uiSchema}
              registry={registry}
            />
          )}
          {hasCopy && (
            <CopyButton disabled={disabled || readonly} onClick={onCopyClick} uiSchema={uiSchema} registry={registry} />
          )}
          {hasRemove && (
            <RemoveButton
              disabled={disabled || readonly}
              onClick={onRemoveClick}
              uiSchema={uiSchema}
              registry={registry}
            />
          )}
        </HStack>
      )}
      <Box w='100%'>{children}</Box>
    </VStack>
  )
}

export function CustomFormLabel(props: any) {
  return <FormLabel {...props} />
}

export function AddButton<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>({
  uiSchema,
  registry,
  ...props
}: IconButtonProps<T, S, F>) {
  const { translateString } = registry
  return (
    <Button
      leftIcon={
        <Icon boxSize='icon-lg'>
          <path d={mdiPlus} />
        </Icon>
      }
      size='sm'
      variant='outline'
      {...props}
    >
      Add item
    </Button>
  )
}
export function ChakraIconButton<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: IconButtonProps<T, S, F>
) {
  return (
    <Tooltip label={props.title}>
      {/* @ts-ignore */}
      <IconButton icon={props.icon} aria-label={props.title} size='xs' variant='outline' {...props}></IconButton>
    </Tooltip>
  )
}
export function CopyButton<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: IconButtonProps<T, S, F>
) {
  const {
    registry: { translateString }
  } = props
  return (
    <ChakraIconButton<T, S, F>
      title={'Copy'}
      {...props}
      icon={
        <Icon boxSize='icon-lg'>
          <path d={mdiContentDuplicate} />
        </Icon>
      }
    />
  )
}

export function MoveDownButton<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: IconButtonProps<T, S, F>
) {
  const { uiSchema, ...rest } = props
  return (
    <ChakraIconButton<T, S, F>
      title={'Move down'}
      {...rest}
      icon={
        <Icon boxSize='icon-lg'>
          <path d={mdiArrowDown} />
        </Icon>
      }
    />
  )
}

export function MoveUpButton<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: IconButtonProps<T, S, F>
) {
  const { uiSchema, ...rest } = props
  return (
    <ChakraIconButton<T, S, F>
      title={'Move up'}
      {...rest}
      icon={
        <Icon boxSize='icon-lg'>
          <path d={mdiArrowUp} />
        </Icon>
      }
    />
  )
}

export function RemoveButton<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: IconButtonProps<T, S, F>
) {
  const { uiSchema, ...rest } = props
  return (
    <ChakraIconButton<T, S, F>
      title={'Remove'}
      {...rest}
      icon={
        <Icon boxSize='icon-lg'>
          <path d={mdiTrashCanOutline} />
        </Icon>
      }
    />
  )
}
export function ArrayFieldTemplate<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: ArrayFieldTemplateProps<T, S, F>
) {
  const { canAdd, disabled, idSchema, uiSchema, items, onAddClick, readonly, registry, required, schema, title } = props
  const uiOptions = getUiOptions<T, S, F>(uiSchema)
  const ArrayFieldDescriptionTemplate = getTemplate<'ArrayFieldDescriptionTemplate', T, S, F>(
    'ArrayFieldDescriptionTemplate',
    registry,
    uiOptions
  )
  const ArrayFieldItemTemplate = getTemplate<'ArrayFieldItemTemplate', T, S, F>(
    'ArrayFieldItemTemplate',
    registry,
    uiOptions
  )
  const ArrayFieldTitleTemplate = getTemplate<'ArrayFieldTitleTemplate', T, S, F>(
    'ArrayFieldTitleTemplate',
    registry,
    uiOptions
  )
  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton }
  } = registry.templates
  return (
    <Box>
      <Divider orientation='horizontal' mb={6} />
      {title && !titleId<T>(idSchema).includes('root__') && (
        <Text fontSize='16px' lineHeight='22px' fontWeight={600} color={'blackAlpha.800'} mb={4}>
          {labelWithTooltip(uiOptions.title || title, uiOptions.description || schema.description)}
        </Text>
      )}
      <Grid key={`array-item-list-${idSchema.$id}`}>
        <GridItem>
          {items.length > 0 &&
            items.map(({ key, ...itemProps }: ArrayFieldTemplateItemType<T, S, F>) => (
              <ArrayFieldItemTemplate key={key} {...itemProps} />
            ))}
          {items.length == 0 && (
            <>
              <Text color='gray.400' mb={2}>
                No items added yet
              </Text>
            </>
          )}
        </GridItem>
        {canAdd && (
          <GridItem justifySelf={'flex-start'}>
            <Box mt={2}>
              <AddButton
                className='array-item-add'
                onClick={onAddClick}
                disabled={disabled || readonly}
                uiSchema={uiSchema}
                registry={registry}
              />
            </Box>
          </GridItem>
        )}
      </Grid>
    </Box>
  )
}
function labelWithTooltip(label: string, description: string) {
  return (
    <>
      {label}
      {description && (
        <>
          {' '}
          <Tooltip label={description}>
            <Icon fontSize='icon-lg' ml={2} mt={'-2px'}>
              <path d={mdiInformationOutline} />
            </Icon>
          </Tooltip>
        </>
      )}
    </>
  )
}

export function ObjectFieldTemplate<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: ObjectFieldTemplateProps<T, S, F>
) {
  const {
    description,
    title,
    properties,
    required,
    disabled,
    readonly,
    uiSchema,
    idSchema,
    schema,
    formData,
    onAddClick,
    registry
  } = props
  const uiOptions = getUiOptions<T, S, F>(uiSchema)

  const itemFields = ['name', 'displayName', 'id', 'url']
  const isItem = itemFields.every((key) => properties.map((p) => p.name).includes(key))
  const [expanded, setExpanded] = useState(!isItem)

  // Button templates are not overridden in the uiSchema
  const {
    ButtonTemplates: { AddButton }
  } = registry.templates

  // avoid demonstratibng Value sub-object (in XM items)
  if (properties.length == 1 && properties[0].name == 'value') {
    return properties[0].content
  }
  return (
    <>
      {title && !titleId<T>(idSchema).includes('root__') && title != 'Fields' && (
        <>
          <Text fontSize='14px' lineHeight='20px' fontWeight={600} color={'blackAlpha.600'} mb={4}>
            {labelWithTooltip(title, description)}
            {isItem && !expanded && (
              <>
                <IconButton
                  size='small'
                  variant={'secondary'}
                  aria-label='Expand metadata'
                  px={1}
                  py={1}
                  ml={1}
                  icon={
                    <Tooltip label='Edit item metadata'>
                      <Icon boxSize='icon-lg' onClick={() => setExpanded(true)}>
                        <path d={mdiChevronDown} />
                      </Icon>
                    </Tooltip>
                  }
                />
              </>
            )}
          </Text>
        </>
      )}
      <Grid gap={titleId<T>(idSchema).includes('root__') ? 6 : 2} mb={4}>
        {properties.map((element, index) =>
          element.hidden ? (
            element.content
          ) : (
            <GridItem
              key={`${idSchema.$id}-${element.name}-${index}`}
              hidden={isItem && itemFields.includes(element.name) && !expanded}
              bg={isItem && itemFields.includes(element.name) ? '#f0f0f0' : ''}
              m={-2}
              p={2}
            >
              {element.content}
            </GridItem>
          )
        )}
        {canExpand<T, S, F>(schema, uiSchema, formData) && (
          <GridItem justifySelf='flex-start'>
            <AddButton
              className='object-property-expand'
              onClick={onAddClick(schema)}
              disabled={disabled || readonly}
              uiSchema={uiSchema}
              registry={registry}
            />
          </GridItem>
        )}
      </Grid>
    </>
  )
}
export interface ChakraUiSchema extends Omit<UiSchema, 'ui:options'> {
  'ui:options'?: ChakraUiOptions
}

type ChakraUiOptions = UiSchema['ui:options'] & { chakra?: ChakraProps }

interface GetChakraProps {
  uiSchema?: ChakraUiSchema
}

export function getChakra({ uiSchema = {} }: GetChakraProps): ChakraProps {
  const chakraProps = (uiSchema['ui:options'] && uiSchema['ui:options'].chakra) || {}

  Object.keys(chakraProps).forEach((key) => {
    /**
     * Leveraging `shouldForwardProp` to remove props
     *
     * This is a utility function that's used in `@chakra-ui/react`'s factory function.
     * Normally, it prevents ChakraProps from being passed to the DOM.
     * In this case we just want to delete the unknown props. So we flip the boolean.
     */
    if (shouldForwardProp(key)) {
      delete (chakraProps as any)[key]
    }
  })

  return chakraProps
}

export function CheckboxesWidget<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: WidgetProps<T, S, F>
) {
  const {
    id,
    disabled,
    options,
    value,
    readonly,
    onChange,
    onBlur,
    onFocus,
    required,
    label,
    hideLabel,
    schema,
    uiSchema,
    rawErrors = []
  } = props
  const { enumOptions, enumDisabled, emptyValue } = options
  const chakraProps = getChakra({ uiSchema })
  const checkboxesValues = Array.isArray(value) ? value : [value]

  const _onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement | any>) =>
    onBlur(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue))
  const _onFocus = ({ target: { value } }: FocusEvent<HTMLInputElement | any>) =>
    onFocus(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue))

  const row = options ? options.inline : false
  const selectedIndexes = enumOptionsIndexForValue<S>(value, enumOptions, true) as string[]

  return (
    <FormControl
      mb={1}
      {...chakraProps}
      isDisabled={disabled || readonly}
      isRequired={required}
      isReadOnly={readonly}
      isInvalid={rawErrors && rawErrors.length > 0}
    >
      {labelValue(
        <CustomFormLabel htmlFor={id} id={`${id}-label`}>
          {labelWithTooltip(label, schema?.description)}
        </CustomFormLabel>,
        hideLabel || !label
      )}
      <CheckboxGroup
        onChange={(option) => onChange(enumOptionsValueForIndex<S>(option, enumOptions, emptyValue))}
        defaultValue={selectedIndexes}
        aria-describedby={ariaDescribedByIds<T>(id)}
      >
        <Stack direction={row ? 'row' : 'column'}>
          {Array.isArray(enumOptions) &&
            enumOptions.map((option, index) => {
              const checked = enumOptionsIsSelected<S>(option.value, checkboxesValues)
              const itemDisabled = Array.isArray(enumDisabled) && enumDisabled.indexOf(option.value) !== -1
              return (
                <Checkbox
                  key={index}
                  id={optionId(id, index)}
                  name={id}
                  value={String(index)}
                  isChecked={checked}
                  isDisabled={disabled || itemDisabled || readonly}
                  onBlur={_onBlur}
                  onFocus={_onFocus}
                >
                  {option.label && <Text>{option.label}</Text>}
                </Checkbox>
              )
            })}
        </Stack>
      </CheckboxGroup>
    </FormControl>
  )
}

export function RadioWidget<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>({
  id,
  schema,
  options,
  value,
  required,
  disabled,
  readonly,
  label,
  hideLabel,
  onChange,
  onBlur,
  onFocus,
  uiSchema
}: WidgetProps<T, S, F>) {
  const { enumOptions, enumDisabled, emptyValue } = options
  const chakraProps = getChakra({ uiSchema })

  const _onChange = (nextValue: any) => onChange(enumOptionsValueForIndex<S>(nextValue, enumOptions, emptyValue))
  const _onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) =>
    onBlur(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue))
  const _onFocus = ({ target: { value } }: FocusEvent<HTMLInputElement>) =>
    onFocus(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue))

  const row = options ? options.inline : false
  const selectedIndex = (enumOptionsIndexForValue<S>(value, enumOptions) as string) ?? null

  return (
    <FormControl mb={1} {...chakraProps} isDisabled={disabled || readonly} isRequired={required} isReadOnly={readonly}>
      {labelValue(
        <CustomFormLabel htmlFor={id} id={`${id}-label`}>
          {labelWithTooltip(label, schema?.description)}
        </CustomFormLabel>,
        hideLabel || !label
      )}
      <RadioGroup
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        value={selectedIndex}
        name={id}
        aria-describedby={ariaDescribedByIds<T>(id)}
      >
        <Stack direction={row ? 'row' : 'column'}>
          {Array.isArray(enumOptions) &&
            enumOptions.map((option, index) => {
              const itemDisabled = Array.isArray(enumDisabled) && enumDisabled.indexOf(option.value) !== -1

              return (
                <Radio
                  value={String(index)}
                  key={index}
                  id={optionId(id, index)}
                  disabled={disabled || itemDisabled || readonly}
                >
                  {option.label}
                </Radio>
              )
            })}
        </Stack>
      </RadioGroup>
    </FormControl>
  )
}

export function TextareaWidget<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>({
  id,
  placeholder,
  value,
  label,
  hideLabel,
  disabled,
  autofocus,
  readonly,
  onBlur,
  onFocus,
  onChange,
  options,
  uiSchema,
  schema,
  required,
  rawErrors
}: WidgetProps<T, S, F>) {
  const chakraProps = getChakra({ uiSchema })

  const _onChange = ({ target: { value } }: ChangeEvent<HTMLTextAreaElement>) =>
    onChange(value === '' ? options.emptyValue : value)
  const _onBlur = ({ target: { value } }: FocusEvent<HTMLTextAreaElement>) => onBlur(id, value)
  const _onFocus = ({ target: { value } }: FocusEvent<HTMLTextAreaElement>) => onFocus(id, value)

  return (
    <FormControl
      mb={1}
      {...chakraProps}
      isDisabled={disabled || readonly}
      isRequired={required}
      isReadOnly={readonly}
      isInvalid={rawErrors && rawErrors.length > 0}
    >
      {labelValue(
        <CustomFormLabel htmlFor={id}>{labelWithTooltip(label, schema?.description)}</CustomFormLabel>,
        hideLabel || !label
      )}
      <Textarea
        id={id}
        name={id}
        value={value ?? ''}
        placeholder={placeholder}
        autoFocus={autofocus}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        aria-describedby={ariaDescribedByIds<T>(id)}
      />
    </FormControl>
  )
}

export function SelectWidget<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: WidgetProps<T, S, F>
) {
  const {
    id,
    options,
    label,
    hideLabel,
    placeholder,
    multiple,
    required,
    disabled,
    readonly,
    value,
    autofocus,
    onChange,
    onBlur,
    onFocus,
    rawErrors = [],
    uiSchema,
    schema
  } = props
  const { enumOptions, enumDisabled, emptyValue } = options
  const chakraProps = getChakra({ uiSchema })

  const _onMultiChange = (e: any) => {
    return onChange(
      enumOptionsValueForIndex<S>(
        e.map((v: { value: any }) => {
          return v.value
        }),
        enumOptions,
        emptyValue
      )
    )
  }

  const _onChange = (e: any) => {
    return onChange(enumOptionsValueForIndex<S>(e.value, enumOptions, emptyValue))
  }

  const _onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) =>
    onBlur(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue))

  const _onFocus = ({ target: { value } }: FocusEvent<HTMLInputElement>) =>
    onFocus(id, enumOptionsValueForIndex<S>(value, enumOptions, emptyValue))

  const _valueLabelMap: any = {}
  const displayEnumOptions: OptionsOrGroups<any, any> = Array.isArray(enumOptions)
    ? enumOptions.map((option: EnumOptionsType<S>, index: number) => {
        const { value, label } = option
        _valueLabelMap[index] = label || String(value)
        return {
          label,
          value: String(index),
          isDisabled: Array.isArray(enumDisabled) && enumDisabled.indexOf(value) !== -1
        }
      })
    : []

  const isMultiple = typeof multiple !== 'undefined' && multiple !== false && Boolean(enumOptions)
  const selectedIndex = enumOptionsIndexForValue<S>(value, enumOptions, isMultiple)
  const formValue: any = isMultiple
    ? ((selectedIndex as string[]) || []).map((i: string) => {
        return {
          label: _valueLabelMap[i],
          value: i
        }
      })
    : {
        label: _valueLabelMap[selectedIndex as string] || '',
        selectedIndex
      }

  return (
    <FormControl
      mb={1}
      {...chakraProps}
      isDisabled={disabled || readonly}
      isRequired={required}
      isReadOnly={readonly}
      isInvalid={rawErrors && rawErrors.length > 0}
    >
      {labelValue(
        <CustomFormLabel htmlFor={id} id={`${id}-label`}>
          {labelWithTooltip(label, schema?.description)}
        </CustomFormLabel>,
        hideLabel || !label
      )}
      <Select
        inputId={id}
        name={id}
        isMulti={isMultiple}
        options={displayEnumOptions}
        placeholder={placeholder}
        closeMenuOnSelect={!isMultiple}
        onBlur={_onBlur}
        onChange={isMultiple ? _onMultiChange : _onChange}
        onFocus={_onFocus}
        autoFocus={autofocus}
        value={formValue}
        aria-describedby={ariaDescribedByIds<T>(id)}
      />
    </FormControl>
  )
}
export function RangeWidget<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>({
  value,
  readonly,
  disabled,
  onBlur,
  onFocus,
  options,
  uiSchema,
  onChange,
  label,
  hideLabel,
  schema,
  id
}: WidgetProps<T, S, F>) {
  const chakraProps = getChakra({ uiSchema })

  const sliderWidgetProps = { value, label, id, ...rangeSpec<S>(schema) }

  const _onChange = (value: undefined | number) => onChange(value === undefined ? options.emptyValue : value)
  const _onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) => onBlur(id, value)
  const _onFocus = ({ target: { value } }: FocusEvent<HTMLInputElement>) => onFocus(id, value)

  return (
    <FormControl mb={1} {...chakraProps}>
      {labelValue(
        <CustomFormLabel htmlFor={id}>{labelWithTooltip(label, schema?.description)}</CustomFormLabel>,
        hideLabel || !label
      )}
      <Slider
        {...sliderWidgetProps}
        id={id}
        name={id}
        isDisabled={disabled || readonly}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        aria-describedby={ariaDescribedByIds<T>(id)}
      >
        <SliderTrack>
          <SliderFilledTrack />
        </SliderTrack>
        <SliderThumb />
      </Slider>
    </FormControl>
  )
}

export function CheckboxWidget<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: WidgetProps<T, S, F>
) {
  const {
    id,
    value,
    disabled,
    readonly,
    onChange,
    onBlur,
    onFocus,
    label,
    hideLabel,
    registry,
    options,
    uiSchema,
    schema
  } = props
  const chakraProps = getChakra({ uiSchema })
  // Because an unchecked checkbox will cause html5 validation to fail, only add
  // the "required" attribute if the field value must be "true", due to the
  // "const" or "enum" keywords
  const required = schemaRequiresTrueValue<S>(schema)
  const DescriptionFieldTemplate = getTemplate<'DescriptionFieldTemplate', T, S, F>(
    'DescriptionFieldTemplate',
    registry,
    options
  )
  const description = options.description || schema.description

  const _onChange = ({ target: { checked } }: ChangeEvent<HTMLInputElement>) => onChange(checked)
  const _onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement | any>) => onBlur(id, value)
  const _onFocus = ({ target: { value } }: FocusEvent<HTMLInputElement | any>) => onFocus(id, value)

  return (
    <FormControl mb={1} {...chakraProps} isRequired={required}>
      {!hideLabel && !!description && (
        <DescriptionFieldTemplate
          id={descriptionId<T>(id)}
          description={description}
          schema={schema}
          uiSchema={uiSchema}
          registry={registry}
        />
      )}
      <Checkbox
        id={id}
        name={id}
        isChecked={typeof value === 'undefined' ? false : value}
        isDisabled={disabled || readonly}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        aria-describedby={ariaDescribedByIds<T>(id)}
      >
        {labelValue(<Text>{labelWithTooltip(label, schema?.description)}</Text>, hideLabel || !label)}
      </Checkbox>
    </FormControl>
  )
}

export function UpDownWidget<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: WidgetProps<T, S, F>
) {
  const {
    id,
    schema,
    uiSchema,
    readonly,
    disabled,
    label,
    hideLabel,
    value,
    onChange,
    onBlur,
    onFocus,
    rawErrors,
    required
  } = props

  const chakraProps = getChakra({ uiSchema })

  const _onChange = (value: string | number) => onChange(value)
  const _onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement | any>) => onBlur(id, value)
  const _onFocus = ({ target: { value } }: FocusEvent<HTMLInputElement | any>) => onFocus(id, value)

  return (
    <FormControl
      mb={1}
      {...chakraProps}
      isDisabled={disabled || readonly}
      isRequired={required}
      isReadOnly={readonly}
      isInvalid={rawErrors && rawErrors.length > 0}
    >
      {labelValue(
        <CustomFormLabel htmlFor={id}>{labelWithTooltip(label, schema?.description)}</CustomFormLabel>,
        hideLabel || !label
      )}
      <NumberInput
        value={value ?? ''}
        onChange={_onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        aria-describedby={ariaDescribedByIds<T>(id)}
      >
        <NumberInputField id={id} name={id} />
        <NumberInputStepper>
          <NumberIncrementStepper />
          <NumberDecrementStepper />
        </NumberInputStepper>
      </NumberInput>
    </FormControl>
  )
}

export function BaseInputTemplate<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: BaseInputTemplateProps<T, S, F>
) {
  const {
    id,
    type,
    value,
    label,
    hideLabel,
    schema,
    uiSchema,
    onChange,
    onChangeOverride,
    onBlur,
    onFocus,
    options,
    required,
    readonly,
    rawErrors,
    autofocus,
    placeholder,
    description,
    rawDescription,
    disabled
  } = props
  const inputProps = getInputProps<T, S, F>(schema, type, options)
  const chakraProps = getChakra({ uiSchema })

  const _onChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) =>
    onChange(value === '' ? options.emptyValue : value)
  const _onBlur = ({ target: { value } }: FocusEvent<HTMLInputElement>) => onBlur(id, value)
  const _onFocus = ({ target: { value } }: FocusEvent<HTMLInputElement>) => onFocus(id, value)

  return (
    <FormControl
      mb={1}
      {...chakraProps}
      isDisabled={disabled || readonly}
      isRequired={required}
      isReadOnly={readonly}
      isInvalid={rawErrors && rawErrors.length > 0}
    >
      {labelValue(
        <CustomFormLabel htmlFor={id} id={`${id}-label`}>
          {labelWithTooltip(label, schema?.description)}
        </CustomFormLabel>,
        hideLabel || !label
      )}
      <Input
        id={id}
        name={id}
        value={value || value === 0 ? value : ''}
        onChange={onChangeOverride || _onChange}
        onBlur={_onBlur}
        onFocus={_onFocus}
        autoFocus={autofocus}
        placeholder={placeholder}
        {...inputProps}
        list={schema.examples ? examplesId<T>(id) : undefined}
        aria-describedby={ariaDescribedByIds<T>(id, !!schema.examples)}
      />
      {Array.isArray(schema.examples) ? (
        <datalist id={examplesId<T>(id)}>
          {(schema.examples as string[])
            .concat(schema.default && !schema.examples.includes(schema.default) ? ([schema.default] as string[]) : [])
            .map((example: any) => {
              return <option key={example} value={example} />
            })}
        </datalist>
      ) : null}
    </FormControl>
  )
}
export function FieldTemplate<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: FieldTemplateProps<T, S, F>
) {
  const {
    id,
    children,
    classNames,
    style,
    disabled,
    displayLabel,
    hidden,
    label,
    onDropPropertyClick,
    onKeyChange,
    readonly,
    registry,
    required,
    rawErrors = [],
    errors,
    help,
    description,
    rawDescription,
    schema,
    uiSchema
  } = props
  const uiOptions = getUiOptions<T, S, F>(uiSchema)
  const WrapIfAdditionalTemplate = getTemplate<'WrapIfAdditionalTemplate', T, S, F>(
    'WrapIfAdditionalTemplate',
    registry,
    uiOptions
  )

  if (hidden) {
    return <div style={{ display: 'none' }}>{children}</div>
  }

  return (
    <WrapIfAdditionalTemplate
      classNames={classNames}
      style={style}
      disabled={disabled}
      id={id}
      label={label}
      onDropPropertyClick={onDropPropertyClick}
      onKeyChange={onKeyChange}
      readonly={readonly}
      required={required}
      schema={schema}
      uiSchema={uiSchema}
      registry={registry}
    >
      <FormControl isRequired={required} isInvalid={rawErrors && rawErrors.length > 0}>
        {children}
        {displayLabel && rawDescription ? <Text mt={2}>{description}</Text> : null}
        {errors}
        {help}
      </FormControl>
    </WrapIfAdditionalTemplate>
  )
}
/**
 * The `FieldHelpTemplate` component renders any help desired for a field.
 *
 * @param props  - The `FieldHelpProps` to be rendered.
 */
export function FieldHelpTemplate<T = any, S extends StrictRJSFSchema = RJSFSchema, F extends FormContextType = any>(
  props: FieldHelpProps<T, S, F>
) {
  const { idSchema, help } = props
  if (!help) {
    return null
  }
  const id = helpId<T>(idSchema)
  return <FormHelperText id={id}>{help}</FormHelperText>
}

const RJSFTemplates: FormProps['templates'] = {
  BaseInputTemplate: BaseInputTemplate,
  ObjectFieldTemplate: ObjectFieldTemplate,
  FieldTemplate: FieldTemplate,
  FieldHelpTemplate: FieldHelpTemplate,
  ArrayFieldItemTemplate: ArrayFieldItemTemplate,
  ArrayFieldTemplate: ArrayFieldTemplate,
  DescriptionFieldTemplate: () => <></>,
  ButtonTemplates: {
    AddButton,
    CopyButton,
    MoveDownButton,
    MoveUpButton,
    RemoveButton
  }
}
const RJSFWidgets: FormProps['widgets'] = {
  CheckboxWidget: CheckboxWidget,
  RadioWidget: RadioWidget,
  UpDownWidget: UpDownWidget,
  SelectWidget: SelectWidget,
  CheckboxesWidget: CheckboxesWidget,
  TextareaWidget: TextareaWidget,
  RangeWidget: RangeWidget
}
export const SchemaForm: typeof Form = (props) => {
  return <Form templates={RJSFTemplates} widgets={RJSFWidgets} {...props} />
}

export default SchemaForm
