import { Button, Modal, ModalContent, ModalHeader, ModalFooter, ModalBody, Box } from '@chakra-ui/react'
import { useContext } from 'react'

import { EntityContext } from '../../contexts/EntityContext.js'
import { EntitiesContext } from '../../contexts/EntitiesContext.js'
import { css } from '@emotion/react'
import { ModalOverlay } from '@chakra-ui/react'

const LG_MODAL = ['font', 'text', 'inline', 'block', 'theme']
const MD_MODAL = ['typography', 'decoration', 'spacing']
const SM_MODAL = ['fill']

const ModalContentStyle = css`
  .chakra-form__label {
    font-weight: var(--chakra-fontWeights-medium);
    color: var(--chakra-colors-black);
    font-size: var(--chakra-fontSizes-md);
  }
`

const EntityModal = ({ children }: { children: any }) => {
  const {
    id,
    name,
    type,
    isActive,
    getClassname,
    onToggleEntity,
    isNew,
    onSafeDelete,
    onUnsafeDelete,
    hasChanges,
    onDiscard,
    onSave,
    hasErrors,
    modalHeader,
    modalFooter,
    invalidNonEmptyFields,
    emptyRequiredFields
  } = useContext(EntityContext)

  const { setEditingAlert } = useContext(EntitiesContext)

  const suffix = `entity ${name} with id: ${id}`

  const maxWidth = LG_MODAL.includes(type) ? '55vw' : MD_MODAL.includes(type) ? '36vw' : '392px'

  const onClose = () => {
    if (isNew && onSafeDelete) {
      onUnsafeDelete()
      return
    }
    if (!isNew && hasChanges) {
      setEditingAlert({
        onDiscard: onDiscard,
        invalidNonEmptyFields,
        emptyRequiredFields
      })
      return
    }
    onToggleEntity(null, true)
  }

  return (
    <Modal isOpen={isActive} onClose={onClose} trapFocus={false}>
      <ModalOverlay />
      <ModalContent
        bg='gray.50'
        maxWidth={maxWidth}
        className={`entity-modal-content ${getClassname('modal-content')}`}
        background='white'
        css={ModalContentStyle}
        fontSize='md'
      >
        <ModalHeader px={6} pb={2}>
          {modalHeader}
        </ModalHeader>
        <ModalBody
          p={0}
          flexDirection='column'
          position='relative'
          id='entity-modal-body-wrapper'
          _before={{
            content: '""',
            height: 2,
            position: 'absolute',
            top: 0,
            left: 0,
            right: 0,
            zIndex: 2,
            background: 'linear-gradient(to bottom, rgb(0 0 0 / 10%), transparent)'
          }}
        >
          <Box
            px={6}
            py={3}
            minHeight={
              LG_MODAL.includes(type) ? '75vh' : MD_MODAL.includes(type) || SM_MODAL.includes(type) ? '65vh' : ''
            }
            maxHeight='75vh'
            overflow='visible auto'
            position='relative'
            _before={{
              content: '""',
              height: 3,
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              zIndex: 2,
              background: 'linear-gradient(var(--chakra-colors-white) 45%, transparent)'
            }}
          >
            {children}
          </Box>
        </ModalBody>

        <ModalFooter pt={3}>
          {modalFooter || (
            <>
              <Button
                size='sm'
                variant='minimal'
                colorScheme='primary'
                className='close-entity'
                aria-label={
                  isNew && onSafeDelete
                    ? `Delete ${suffix}`
                    : hasChanges && !isNew
                    ? `Cancel ${suffix}`
                    : `Close ${suffix}`
                }
                onClick={onClose}
              >
                Cancel
              </Button>

              <Button
                size='sm'
                variant='primary'
                ml={2}
                aria-label={`Save ${suffix}`}
                isDisabled={hasErrors || (!hasChanges && !isNew)}
                onClick={(e) => {
                  onSave()
                  onToggleEntity(e, true)
                }}
              >
                Save
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default EntityModal
