import { Divider, Img, Tooltip, VStack } from '@chakra-ui/react'
import { ExternalComponentModel, Style, getEmbedDefinition, DatasourceModel, Datasource } from '@sitecore-feaas/sdk'
import { useContext, useEffect, useMemo, useState } from 'react'
import {
  AttributeCallback,
  AttributeGetter,
  ContextCallback,
  Dialog,
  DialogAttribute,
  DialogStyle,
  RuleCallback,
  dialogs
} from '../editor/dialog/Dialog.js'
import DialogAttributesExternalSchema from '../editor/dialog/DialogAttributesExternalSchema.js'
import { DialogMenu } from '../editor/dialog/DialogMenu.js'
import DialogMenuAttributes from '../editor/dialog/DialogMenuAttrbutes.js'
import DialogMenuComponent from '../editor/dialog/DialogMenuComponent.js'
import DialogMenuDatasources from '../editor/dialog/DialogMenuDatasources.js'
import { DialogMenuTheme } from '../editor/dialog/DialogMenuTheme.js'
import type { PickerProps } from './Picker.js'
import { PickerWrapper } from './PickerWrapper.js'
import { Link } from '@chakra-ui/react'
import { getComponentConfigurablePropertyNames, objectKeysToCamelCase } from '@sitecore/byoc'
import { EnvironmentContext } from '../providers/EnvironmentProvider.js'
import { useSDK } from '../../exports.js'
import { mdiPlus } from '@mdi/js'

export type PickerContextProps = {
  onBack?: () => void
  data: PickerProps['data']
  rules: Style.Rule[]
  customRules: Style.Rule[]
  themeClassList: string[]
  classList: string[]
  context: Style.Context
  contextLink: Style.Context
  contextVariable: Style.Context
  getAttribute: AttributeGetter
  onAttributeChange: AttributeCallback
  onRuleChange: RuleCallback
  onSelect: ContextCallback
  variant: PickerProps['variant']
  theme: Style.Rule<'theme'>
  embed: PickerProps['embed']
  collections: PickerProps['collections']
  externalComponents: PickerProps['externalComponents']
  datasources: DatasourceModel[]
  onModeChange: (mode?: PickerProps['mode'], dialog?: string) => void
  dialog: string
}
export default function PickerContext(props: PickerContextProps) {
  const {
    context,
    onBack,
    onModeChange,
    rules,
    variant,
    customRules,
    data,
    embed,
    theme,
    dialog: dialogName,
    collections,
    externalComponents,
    datasources,
    onAttributeChange,
    getAttribute
  } = props
  const setDialogName = (dialog: string) => onModeChange('context', dialog)

  const [index, setIndex] = useState(0)
  const definition = Style.Context.getDefinition(context)
  const environment = {
    ...props,
    onNavigate: setDialogName,
    definition,
    index,
    setIndex
  }
  const env = useContext(EnvironmentContext)
  const sdk = useSDK()

  const applicableDialogs = dialogs
    .filter((definition) => context && definition.condition(environment))
    .map((definition) => ({
      ...definition,
      ...environment,
      ...('prepare' in definition ? definition.prepare(environment) : {})
    })) as Dialog[]
  const dialog = applicableDialogs.find((d) => d.id == dialogName)
  const styleOverviewDialogs = applicableDialogs.filter((d) => d.group == 'style' && d.id == 'style') as DialogStyle[]
  const styleDialogs = applicableDialogs.filter((d) => d.group == 'style' && d.id != 'style') as DialogStyle[]
  const contentDialogs = applicableDialogs.filter(
    (d) => d.group == 'attribute' && d.id != 'repeating'
  ) as DialogAttribute[]
  const dataDialogs = applicableDialogs.filter(
    (d) => d.group == 'attribute' && d.id == 'repeating'
  ) as DialogAttribute[]

  const customDefinition = Style.Set.getContextDefinition(rules, context, false)
  try {
    var defaultData = objectKeysToCamelCase(JSON.parse((getAttribute(context, 'data-attributes') || '{}') as string))
  } catch (e) {
    defaultData = {}
  }
  const externalComponent = ExternalComponentModel.isExternalComponent(embed) ? embed : null
  const embedDefinition = getEmbedDefinition(context)
  const title = !embed
    ? embedDefinition
      ? embedDefinition?.type == 'html'
        ? 'HTML Block'
        : 'Unknown component'
      : customDefinition?.label
    : ExternalComponentModel.isExternalComponent(embed)
    ? embed.title
    : embed.name
  const component = embed && !ExternalComponentModel.isExternalComponent(embed) ? embed : null
  const versionId = getAttribute(context, 'version')
  const version = versionId
    ? component?.getEmbeddableVersions('saved').find((version) => {
        return versionId == version.id
      })
    : null
  const configurableProperties = externalComponent && getComponentConfigurablePropertyNames(externalComponent)

  // TODO: THIS IS JUST AN EXAMPLE. GET RID OF THIS ONCE IMPLEMENTATION HAS BEEN DONE FROM PAGES SIDE
  // useEffect(() => {
  //   if (externalComponent && !data) {
  //     const externalComponentDefaultData = externalComponents.find((c) => c.id === externalComponent.id)?.getDataSettings()
  //     if (externalComponentDefaultData) onAttributeChange('data', externalComponentDefaultData, context)
  //   }
  // }, [])

  return (
    <PickerWrapper
      title={dialog ? dialog.label : title}
      onBack={dialogName ? () => setDialogName(null) : onBack}
      variant={variant}
      iconRight={
        (component && location.pathname != component.getBuilderPath() && (
          <Tooltip label='Edit component' placement='top-end'>
            <Link href={sdk.getFrontendURL(component.getBuilderPath())} target='_blank' rel='noopener'>
              <Img src='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-components' />
            </Link>
          </Tooltip>
        )) ||
        (embedDefinition?.type == 'form' && (
          <>
            <Tooltip label='Manage Sitecore Forms (in a new tab)' placement='top-end'>
              <Link href={env.formsFrontend + '?' + sdk.getContextQuery()} target='_blank' rel='noopener'>
                <Img src='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-forms' />
              </Link>
            </Tooltip>
          </>
        ))
      }
    >
      {dialogName == null && (
        <VStack alignItems={'stretch'} px={3} spacing={6} divider={<Divider />} py={6}>
          {embedDefinition && embedDefinition.type != 'html' && embedDefinition.type != 'web' && (
            <DialogMenuComponent
              version={version}
              variant={variant}
              context={context}
              embed={embed}
              externalComponents={externalComponents}
              collections={collections}
              onModeChange={onModeChange}
            />
          )}
          {component?.getDatasources()?.length > 0 && (
            // It is not possible to change data source settings for nested FEAAS component yet
            <DialogMenuDatasources
              datasources={component.getDatasources()}
              variant={variant}
              onModeChange={onModeChange}
              data={data}
              onDeleteItem={
                variant === 'pages'
                  ? (datasourceId) => {
                      const newData = { ...data }
                      delete newData[datasourceId]
                      onAttributeChange('data', newData, context)
                    }
                  : null
              }
            />
          )}
          {configurableProperties?.length > 0 && (
            <VStack alignItems={'stretch'} spacing={6} divider={<Divider />}>
              <DialogMenuAttributes
                externalComponent={externalComponent}
                setDialogName={setDialogName}
                options={defaultData}
                variant={variant}
              />
            </VStack>
          )}
          {variant == 'pages' && externalComponent && (
            <>
              <DialogMenuDatasources
                datasources={DatasourceModel.getExternalComponentDatasources(data, datasources)}
                variant={variant}
                onModeChange={onModeChange}
                data={data}
                onDeleteItem={(datasourceId) => {
                  const newData = { ...data }
                  delete newData[datasourceId]
                  onAttributeChange('data', newData, context)
                }}
              />
              {DatasourceModel.getExternalComponentDatasources(data, datasources)?.length > 0 && (
                <DialogMenu
                  title={''}
                  items={[
                    {
                      onNavigate: () => onModeChange('datasources'),
                      id: 'add-datasource',
                      label: 'Add datasource',
                      icon: mdiPlus
                    }
                  ]}
                />
              )}
            </>
          )}
          {variant != 'pages' && styleOverviewDialogs.length > 0 && (
            <DialogMenu
              variant={variant}
              title={'Style'}
              onNavigate={() => onModeChange('element')}
              items={styleOverviewDialogs}
            />
          )}
          {variant != 'pages' && styleDialogs.length > 0 && (
            <DialogMenu
              variant={variant}
              items={styleDialogs}
              onNavigate={setDialogName}
              title={
                definition.name == 'media'
                  ? 'Sizing'
                  : definition.type == 'text'
                  ? 'Alignment'
                  : definition.type == 'inline'
                  ? 'Sizing'
                  : 'Layout & alignment'
              }
            ></DialogMenu>
          )}
          {variant == 'pages' &&
            (!ExternalComponentModel.isExternalComponent(embed) || !ExternalComponentModel.isComponentForm(embed)) && (
              <>
                <DialogMenuTheme theme={theme} rules={rules} onModeChange={onModeChange} variant={variant} />
              </>
            )}
          {variant != 'pages' && contentDialogs.length > 0 && (
            <DialogMenu
              variant={variant}
              items={contentDialogs}
              onNavigate={setDialogName}
              title='Content'
            ></DialogMenu>
          )}
          {dataDialogs.length > 0 && (
            <DialogMenu
              variant={variant}
              items={applicableDialogs.filter((d) => d.group == 'attribute' && d.id == 'repeating')}
              onNavigate={setDialogName}
              title='Data scope'
            ></DialogMenu>
          )}
        </VStack>
      )}
      {dialogName == 'schema' && ExternalComponentModel.isExternalComponent(embed) ? (
        <VStack py={6} px={3} spacing={6} alignItems={'stretch'} role='dialog'>
          <DialogAttributesExternalSchema
            attributes={defaultData}
            embed={embed}
            onChange={(value, attribute) => {
              onAttributeChange(attribute, value)
            }}
          />
        </VStack>
      ) : (
        dialogName != null && <PickerContextDialog {...dialog} />
      )}
    </PickerWrapper>
  )
}

export function PickerContextDialog(props: Dialog) {
  // reember components to avoid react reinsertion
  const { component } = useMemo(() => props, [])
  return (
    <VStack py={6} px={3} spacing={6} alignItems={'stretch'} role='dialog'>
      {component?.(props)}
    </VStack>
  )
}
