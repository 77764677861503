import { mdiCodeBraces, mdiDatabaseOutline } from '@mdi/js'
import { Tag, TagLeftIcon, Text, Image, TagProps, HStack, Tooltip } from '@chakra-ui/react'
import { DatasourceModel } from '@sitecore-feaas/sdk'

export const DatasourceB = {
  display: 'inline-flex'
}

export const Img = {
  height: '18px'
}

const DatasourceBadge = ({
  datasources,
  className,
  ...props
}: {
  datasources: Partial<Pick<DatasourceModel, 'name' | 'type' | 'isExtended'>>[]
  className?: string
} & TagProps) => {
  const isXmTemplate = (type: string) => type === 'xmTemplate'
  const isCH1 = (type: string) => type === 'contentHubOne'
  const isCustom = (type: string) => type == 'internal'
  const isExternal = (type: string) => type == 'external'

  const isSingleDatasource = datasources.length === 1
  const firstDatasource = datasources?.[0]
  const firstDatasourceType = datasources?.[0]?.type
  const isFirstDatasourceCustom = isCustom(firstDatasourceType)
  const isFirstDatasourceExternal = isExternal(firstDatasourceType)

  const extended = datasources?.some((d) => d?.isExtended?.()) && (
    <Tooltip label='The Rendering Host overrides the default data fetching logic for this data source'>
      <Text fontSize={13} as='span' ml='1' color='gray.400'>
        (Extended)
      </Text>
    </Tooltip>
  )
  return (
    <>
      <Tag {...DatasourceB} {...props} className={className}>
        {datasources.length > 1 && (
          <HStack spacing={0}>
            <TagLeftIcon>
              <path d={mdiDatabaseOutline} />
            </TagLeftIcon>
            <Text ml={-1}>Multiple data sources</Text>
          </HStack>
        )}

        {isSingleDatasource && isFirstDatasourceCustom && (
          <HStack spacing={0}>
            <TagLeftIcon>
              <path d={mdiCodeBraces} />
            </TagLeftIcon>
            <Text fontSize={13} fontWeight={400}>
              Custom endpoint
              {extended}
            </Text>
          </HStack>
        )}

        {isSingleDatasource && isXmTemplate(firstDatasourceType) && (
          <HStack>
            <Image
              {...Img}
              src='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-xm_cloud'
              alt='xm cloud template'
              height='14px'
              width='14px'
              display='inline-block'
              style={{ verticalAlign: '-3px' }}
            />
            <Text fontSize={13} fontWeight={400}>
              XM Cloud
              {extended}
            </Text>
          </HStack>
        )}

        {isSingleDatasource && isCH1(firstDatasourceType) && (
          <HStack>
            <Image
              {...Img}
              src='https://delivery-sitecore.sitecorecontenthub.cloud/api/public/content/mark-content_hub_one'
              alt='content hub one datasource'
              height='14px'
              width='14px'
              display='inline-block'
              style={{ verticalAlign: '-3px' }}
            />
            <Text fontSize={13} fontWeight={400}>
              Content Hub One
              {extended}
            </Text>
          </HStack>
        )}

        {isSingleDatasource && isFirstDatasourceExternal && (
          <HStack spacing={0}>
            <TagLeftIcon>
              <path d={mdiCodeBraces} />
            </TagLeftIcon>
            <Text fontSize={13} fontWeight={400}>
              External
              {extended}
            </Text>
          </HStack>
        )}
      </Tag>
    </>
  )
}

export default DatasourceBadge
